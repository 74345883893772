import React, { useState } from "react"
import { Link } from "gatsby"
import { Drawer } from "@material-ui/core"
import { Container } from "./Container"
import { Logo } from "./Logo"
import MenuIcon from "../assets/menu.svg"
import CloseIcon from "../assets/close.svg"

export const Header = props => {
  const [open, setOpen] = useState(false)
  const navLinkClassName =
    "py-6 px-6 text-4xl z-10 font-serif font-semibold uppercase md:py-0 md:text-lg"
  const showMenu = () => {
    setOpen(true)
  }
  const hideMenu = () => {
    setOpen(false)
  }
  return (
    <header className=" top-0 z-20 ">
      <Container>
        <div className="flex items-center justify-between py-4">
          <Link
            to="/"
            className="text-primary-dark font-serif font-bold text-xl tracking-wide"
          >
            <Logo className="w-8 h-8" />
          </Link>
          <div className="hidden md:flex md:flex-row md:justify-end">
            <Link className={navLinkClassName} to="/about/">
              about
            </Link>
            <Link className={navLinkClassName} to="/projects/">
              projects
            </Link>
            <Link className={navLinkClassName} to="/contact/">
              contact
            </Link>
          </div>
          <MenuIcon
            className="md:hidden"
            fill="#bc353b"
            onClick={() => showMenu()}
          />
          <Drawer
            className="z-10  w-screen relative top-0"
            anchor="top"
            open={open}
            onClose={() => hideMenu()}
          >
            <nav className="h-screen flex flex-col justify-center items-center bg-secondary text-tertiary-dark">
              <CloseIcon
                fill="#b6b08c"
                className="absolute right-0 top-0 p-4 w-16 h-16"
                onClick={() => hideMenu()}
              />
              <Link className={navLinkClassName} to="/about/">
                about
              </Link>
              <Link className={navLinkClassName} to="/projects/">
                projects
              </Link>
              <Link className={navLinkClassName} to="/contact/">
                contact
              </Link>
            </nav>
          </Drawer>
        </div>
      </Container>
    </header>
  )
}
