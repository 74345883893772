import React from "react"
import { Container } from "./Container"

export const Footer = () => {
  return (
    <footer className="bg-secondary-light text-white mt-auto">
      <Container>
        <p className="text-sm font-serif text-center py-6">
          Copyright &copy; Elizabeth Vincent M 2020
        </p>
      </Container>
    </footer>
  )
}
