import React from "react"

export const Logo = props => (
  <svg className={props.className} viewBox="0 0 140 145">
    <path
      d="M114.42 116.9c-4.64 3.24-7.54 5.27-8.7 6.08-7.27 5.1-15.66 8.38-24.46 9.58-1.67.23 3.83-.52 1.44-.2-6.49.89-13.09.84-19.57-.13-2-.3.42.06-1.92-.29-5.33-.8-10.45-2.64-15.06-5.43-3.18-1.92-3.76-2.27-6.45-3.9-6.25-3.77-11.69-8.75-16-14.64-2.02-2.76-.81-1.11-2.64-3.62-4.88-6.66-7.83-14.54-8.53-22.77-.2-2.36.22 2.52 0 0-.67-7.81.1-15.67 2.25-23.2.95-3.32.18-.63 1.04-3.64 2.44-8.52 6.87-16.34 12.92-22.82 1.22-1.3-3.21 3.44-1.71 1.84 5.17-5.55 11.07-10.37 17.52-14.35 1.72-1.06-2.31 1.42-.46.28 5.96-3.68 12.66-5.97 19.62-6.7 5.4-.57 1.18-.12 4.11-.43 14.52-1.53 28.98 3.36 39.59 13.39 1.4 1.32-1.88-1.78 1.83 1.73 3.42 3.23 6.19 7.08 8.17 11.36.65 1.42 2.29 4.97 4.92 10.65L19.53 90.21"
      fillOpacity="0"
      className="logoPath"
      stroke="#bc353b"
      strokeWidth="19"
    />
  </svg>
)
